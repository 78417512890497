import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    IconButton,
    Stack,
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
    timeZoneData,
    syncCursers,
    formatDates,
    formatAxes,
    defineYSeries,
    exportPng,
    makeChart,
} from './uplotFuncs'
import ChartDescription from "./ChartDescription";
import ChartActionMenu from "./ChartActionMenu"
import "./Uplot.css";
import {
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import DrawIcon from '@mui/icons-material/Draw';
import FunctionsIcon from '@mui/icons-material/Functions';
import { PAPER_ELEVATION, BORDER_RADIUS } from '../../constants';



/**
 * A component that utilizes uPlot to render historical data with performance optimizations using hooks and batched updates.
 * Based on example from https://codesandbox.io/p/sandbox/uplot-react-6ykeb
 * @param {Array} data - Array with data and ids for each chart: {data: Array with data prepared for uPlot, ids:Array with ids}
 * @param {Object} chartDefs - Object with chart definitions 
 * @param {Array} yArrays - The array of y-axis data. One or more series.
 * @param {Object} options - Chart options containing title (Str), chartType (Str), colors (Array), points (Array) with names
 * @param {Str} target - Name of selected model target
 * @param {object} charAttr - Information about the chart template
 * @param {object} viewAttr - Information about the chart
 *  */
const HistorianUPlot = ({ defsAndData, chartSpec, chartDescriptions, blockSize, title, syncKey, tz, divK, charAttr, viewAttr }) => {


    const debug = false
    // Data and definitions
    const { origChartDefs, dateWithTimestamps, origData } = defsAndData
    if (debug) console.log("HistorianUPlot - inputs", defsAndData, chartSpec, divK, charAttr, viewAttr)
    // Dimensioning data
    const { parent, chart } = blockSize
    const { chartWidth, chartHeight } = chart

    const { workspaceWidth, split } = parent
    const theme = useTheme();
    const { t } = useTranslation();
    const divRef = useRef(null);
    const [plot, setPlot] = useState(null)
    const [enabled, setEnabled] = useState(Array(origData.length).fill(true)) // incl time
    const [anchorEl, setAnchorEl] = useState(null);
    // const [tooltip, setTooltip] = useState(false) // later

    // eslint-disable-next-line no-unused-vars
    const [opts, setOpts] = useState({
        id: `${divK}-chart1-float`,
        width: chartWidth,
        height: chartHeight,
        padding: [10, workspaceWidth < split ? 0 : 30, workspaceWidth < split ? 40 : 20, workspaceWidth < split ? 0 : 30], // top,right, bottom, left
        legend: {
            show: true,
        },
        series: [
            {
                label: t("TIME"),
                // stroke: "blue",
                // Format time output
                value: (self, rawValues) => {
                    const date = new Date(rawValues * 1000); // needs millis

                    //Take locale from browser
                    const userLocale = navigator.language || navigator.userLanguage;
                    return date.toLocaleString(userLocale) // "ch-DE"
                },
            },
            ...defineYSeries(origChartDefs, theme)
        ],
        ...timeZoneData(tz),
        ...syncCursers(syncKey),
        ...formatDates(),
        ...formatAxes(origChartDefs, theme),
    })



    useEffect(() => {
        if (!divRef) return;

        // make sure data size fits opts (asynch processes)
        if (origData.length !== origChartDefs.map(m => m.points).flat().length + 1) return

        const div = divRef.current;

        let newChart = makeChart(
            {
                chartDefs: origChartDefs,
                enabled: enabled,
                setEnabled: setEnabled,
                origData: origData,
                chartDiv: div,
                opts: opts,
                blockSize: blockSize,
                divK: divK,
                dateWithTimestamps: dateWithTimestamps,
                theme: theme
            }
        )

        setPlot(newChart)

        // Cleanup function
        return () => {
            if (newChart) {
                newChart.destroy();
                setPlot(null)
            }
        };

    }, [divRef, blockSize, dateWithTimestamps, divK, enabled, opts, origChartDefs, origData, theme]);

    const [highlightedRange, setHighlightedRange] = useState([null, null]);
    // Playground
    // load separate window as overlay
    // disable
    const markIssue = (id, p) => {

        const startIndex = 10; // Start index for the highlight
        const endIndex = 200; // End index for the highlight
        console.log(origData)
        const startTime = origData[0][startIndex]; // Assuming the first column is time
        const endTime = origData[0][endIndex];
        p.setCursor({ left: startTime, top: 0 });
        p.setCursor({ left: endTime, top: 30 });
    }

    

    const Toolbar = () => {
        const padding = 6
        const iconSize = blockSize.toolbar.toolbarWidth - 2 * padding
        const styling = {
            width: iconSize,
            height: iconSize,

        }
        return (
            <Box sx={{
                height: '100%',
                width: workspaceWidth < split ? "auto" : blockSize.toolbar.toolbarWidth,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                boxSizing: 'border-box',
                padding: `${padding}px`,
                paddingBottom: '1rem',
                // position: 'relative', // Set position to relative for overlay positioning
            }}
            >
                <Stack
                    direction={workspaceWidth < split ? "row" : "column"}
                    spacing={0.5}

                >
                    {/* ADD TOOLTIP */}
                    <IconButton
                        className="onPaper"
                        onClick={() => { }}
                        size="small"
                        sx={styling}
                    >
                        <ZoomOutMapIcon />
                    </IconButton>
                    <IconButton
                        className="onPaper"
                        onClick={() => markIssue(`${divK}-canvas`, plot)}
                        size="small"
                        sx={styling}
                    >
                        <DrawIcon />
                    </IconButton>
                    <ChartActionMenu charAttr={charAttr} styling={styling}/>
                    <ChartDescription
                        text={chartDescriptions.find(f => f.key === chartSpec)?.description}
                        blockSize={blockSize}
                        styling={styling}
                    />

                    <IconButton
                        className="onPaper"
                        onClick={() => exportPng(`${divK}-canvas`, plot)}
                        size="small"
                        sx={styling}
                    >
                        <FileDownloadIcon />
                    </IconButton>

                </Stack>
            </Box>
        )
    }


    return (
        <div
            style={{
                width: '100%',
                display: "flex",
                flexDirection: workspaceWidth < split ? "column-reverse" : 'row',
                alignItems: 'start',
                justifyContent: 'left',
            }}
        >
            <div id={`${divK}-legend`} style={{ width: blockSize.legend.legendWidth, height: blockSize.legend.legendTotalHeight }} ></div>
            <div id={divK} ref={divRef} style={{ width: blockSize.chart.chartWidth, display: "flex", flexDirection: "column", alignItems: 'start', justifyContent: 'left' }} />
            <Toolbar></Toolbar>
            <canvas id={`${divK}-canvas`} style={{ display: 'none' }}></canvas>
        </div>
    )



};

export default HistorianUPlot