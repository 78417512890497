import React from "react";
import { Box, Divider, useTheme, useMediaQuery } from "@mui/material";
import CenteredCircularProgress from "../Generic/CenteredCircularProgress";
import PageTitle from "../Generic/PageTitle";
import { SIDEBAR_WIDTH_SM } from "../../constants";
import { useDrawerWidth } from "../../size";
import NotificationDisplay from "../Generic/NotificationDisplay";

const Workspace = ({
  children,
  mainPage,
  isLoading,
  disableTitle = false,
  title,
  addTitleElementRightSide,
  titleIcon,
  getSize,
}) => {
  const theme = useTheme();
  const drawerWidth = useDrawerWidth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isLargeTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );

  return (
    <>
      <NotificationDisplay />

      <Box
        id="workspace"
        sx={{
          marginTop: 0,
          padding: 0,
          background: theme.palette.background.default,
          // paddingBottom: drawerWidth === 0 ? `${paddingBottom}px` : 0,
        }}
      >
        {isLoading && (
          <CenteredCircularProgress
            sxProps={{
              height: `calc(100vh - ${SIDEBAR_WIDTH_SM}px)`,
              marginLeft: `${drawerWidth}px`,
            }}
          />
        )}
        {!isLoading && (
          <>
            <Box
              sx={{
                height: `calc(100vh - ${SIDEBAR_WIDTH_SM}px - ${"1px"})`, // one px to much? divider?
                margin: 0,
                marginLeft:
                  mainPage === 1 || disableTitle ? 0 : `${drawerWidth}px`,
                paddingLeft: disableTitle
                  ? "0px"
                  : isMobile
                  ? "1rem"
                  : isTablet
                  ? "2rem"
                  : isLargeTablet
                  ? "2rem"
                  : "6rem",
                paddingRight: disableTitle
                  ? "0px"
                  : isMobile
                  ? "1rem"
                  : isTablet
                  ? "2rem"
                  : isLargeTablet
                  ? "2rem"
                  : "6rem",
                paddingTop: disableTitle
                  ? "0px"
                  : isMobile
                  ? "1rem"
                  : isTablet
                  ? "3rem"
                  : isLargeTablet
                  ? "3rem"
                  : "6rem",
              }}
            >
              {!disableTitle && (
                <>
                  <PageTitle rightSideElement={addTitleElementRightSide}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: theme.palette.getContrastText(
                          theme.palette.background.default
                        ),
                      }}
                    >
                      {titleIcon !== undefined && (
                        <div style={{ paddingRight: "1rem" }}>{titleIcon}</div>
                      )}
                      {title}
                    </div>
                  </PageTitle>

                  <Divider
                    sx={{
                      mb: 4,
                    }}
                  />
                </>
              )}
              {children}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Workspace;
