import React, { useCallback, useEffect, useState, memo,  useRef } from "react";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import { useTheme, Chip, Stack, Typography } from "@mui/material";
import {
    fetchChartPoints
} from "../actions/api";
import { useTranslation } from "react-i18next";




/**
 * Header element with selectors for campus, main, and subtopic
 * @param {Function} objectHandler - Function that returns the object 'chartObject'
 * @param {Function} objecTargetHandler - Enables chart
 * @param {Array} allTargets - Array with all targes
 * @param {String} selectedTarget - Selected target id from allTargets
 * @param {Object} state - Object with all data
 * @param {Function} onUpdate - Reset the global step value to 0
 * @returns {JSX.Element} The rendered Autocomplete component
 */
const ChartTargetFilterSelection = memo(({ objectHandler, currentStep, selectedCampus, selectedCategory, localState }) => {

    const { filteredTargets, selectedTargetId, selectedChartDef } = localState

    const debug = false
    const theme = useTheme();
    const { t } = useTranslation();

    const [selectedItem, setSelectedItem] = useState(null); // index of selection in filteredTargets
    
    const prevValueRef = useRef();
    const isRunning = useRef(false);

    // API CALL
    const getPointListFromApi = useCallback(async (
        _customerID,
        _selectedCampus,
        _targetId,
        _specs,
        _chartViewDef
    ) => {
        try {
            if(debug) console.log(
                `Fetching chart points for targetId: ${_targetId}, chartSpecs: ${_specs}, chartViewDef: ${_chartViewDef}, customerId: ${_customerID}, campusId: ${_selectedCampus}`
            );

            // Check if essential parameters are provided
            if (!_targetId || !_specs || !_chartViewDef) {
                throw new Error(
                    "Received undefined input argument 'targetId', 'specs', or 'chartViewDef'."
                );
            }

            const data = await fetchChartPoints(
                _customerID,
                _selectedCampus,
                _targetId,
                _specs,
                _chartViewDef
            );

            if(debug) console.log(`Received data for chartViewDef ${_chartViewDef}:`, data);
            if (!Array.isArray(data) || data.length === 0) {
                if (debug) console.log("No data received, return empty list");
                return [];
            } else return data;
        } catch (error) {
            console.error("Error fetching chart points from API:", error);
            return [];
        }
    }, [debug]);

    const handleTargetSelection = useCallback(async (selection, index, cat, step) => {
        if (isRunning.current) {
            console.warn("Function is already running. Ignoring this call.");
            return; // Exit if the function is already running
        }

        if (!selectedCampus || !selection) {
            console.warn("Invalid campus or selection data. Ignoring this call.");
            return;
        }

        isRunning.current = true; // Set the ref to true to indicate the function is running
        try {
            
            
            // API
            const chartSpecs = (selection.charts || []).map((element) => element.spec);
            const chartSpecsString = chartSpecs.join(", ");
            const pointList = await getPointListFromApi(
                selectedCampus.customerID,
                selectedCampus.campusId,
                selection.id,
                chartSpecsString,
                selection.chartDef
            );

            const chartData = chartSpecs.map((spec, index) => {
                const foundData = pointList.find(
                    (chartData) => chartData.chart === spec
                );

                return {
                    ...selection.charts[index],
                    title: selection.charts[index].dis,
                    spec: selection.charts[index].spec,
                    targetId: selection.id,
                    dataSeries: foundData ? foundData.data : [],
                };
            });

            // Pass data to parent
            const chartViewData = (cat.subGroups || []).find(
                (f) => f.chartViewDef === selection.chartDef
            );


            if(debug) console.log("Data updated in Chart object:", {
                //  For links
                charts: chartData,
                selectedTargetId: selectedTargetId,
                selectedChartView: selection.chartDef,
                selectedItem: index,
                chartViewData,
                showChart: true, // enable charts (only here)
                currentStep: step // 3 for direct link, 0 else 
            })
            // Update data
            objectHandler({
                //  For links
                charts: chartData,
                selectedTargetId: selectedTargetId,
                selectedChartView: selection.chartDef,
                selectedItem: index,
                chartViewData,
                showChart: true, // enable charts (only here)
                currentStep: step // 3 for direct link, 0 else 
            });

        } catch (error) {
            console.error("Error during handleChange:", error);
        } finally {
            isRunning.current = false;
        }

    }, [selectedCampus, selectedTargetId, getPointListFromApi, objectHandler, debug])

    // If  both values are preset, call api immediately and set selection
    useEffect(() => {
        // Situation 1
        if (selectedTargetId && selectedChartDef) {
            if (currentStep === 2 && selectedCategory) {
                const ind = filteredTargets.findIndex(k => k.id === selectedTargetId && k.chartDef === selectedChartDef);
                setSelectedItem(ind)
                const target = filteredTargets[ind]
                handleTargetSelection(target, ind, selectedCategory, 3)

            } else {
              
                if (prevValueRef.current !== selectedTargetId) {
                    setSelectedItem(null)
                }
            }
            prevValueRef.current = selectedTargetId
            return
        }

        // Situation 2
        if (prevValueRef.current !== selectedTargetId) {
            setSelectedItem(null)
        }
        
        prevValueRef.current = selectedTargetId
    }, [selectedTargetId, selectedChartDef, selectedCategory, currentStep, filteredTargets, handleTargetSelection])



    const setItem = (index) => {
       
        setSelectedItem(index); // Update the selected item
        const target = filteredTargets[index]
        
        handleTargetSelection(target, index, selectedCategory, 0)
    }

    return (
        <>
            {filteredTargets.length === 0 ? (
                <Typography variant={"body1"} component="h2" sx={{ paddingTop: '1rem' }}>
                    {t("SELECT_TARGET")}
                </Typography>
            ) : (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{ paddingBottom: '1rem', mt: 2 }}
                    useFlexGap
                    flexWrap="wrap"
                >

                    {filteredTargets.map((target, index) => (
                        <Chip
                            key={`${target.chartSubGroup}-${target.id}`}
                            style={{
                                color:
                                    selectedItem === index
                                        ? theme.palette.secondary.contrastText // Contrast text for secondary color
                                        : theme.palette.primary.contrastText, // Default text color
                                backgroundColor:
                                    selectedItem === index
                                        ? theme.palette.secondary.main
                                        : theme.palette.primary.main,
                            }}
                            label={target.chartSubGroup}
                            onClick={() => setItem(index)}
                        />
                    ))}
                </Stack>
            )}
        </>



    );
});

export default ChartTargetFilterSelection;
