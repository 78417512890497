import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";

const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [errors, setErrors] = useState([]);

  // Add a notification and automatically remove it after 6 seconds
  const addNotification = useCallback((message) => {
    setNotifications((prev) => [...prev, message]);
    setTimeout(() => {
      setNotifications((prev) => prev.slice(1)); // Remove the first notification
    }, 6000);
  }, []);

  // Add an error and automatically remove it after 6 seconds
  const addError = useCallback((message) => {
    setErrors((prev) => [...prev, message]);
    setTimeout(() => {
      setErrors((prev) => prev.slice(1));
    }, 6000);
  }, []);

  // Remove a specific notification by index
  const removeNotification = useCallback((index) => {
    setNotifications((prev) => prev.filter((_, i) => i !== index));
  }, []);

  // Remove a specific error by index
  const removeError = useCallback((index) => {
    setErrors((prev) => prev.filter((_, i) => i !== index));
  }, []);

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      notifications,
      addNotification,
      removeNotification,
      errors,
      addError,
      removeError,
    }),
    [
      notifications,
      errors,
      addNotification,
      removeNotification,
      addError,
      removeError,
    ]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
