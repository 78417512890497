import React, { useCallback, useEffect, useState, memo, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import {
  useTheme,
  Box,
  Card,
  useMediaQuery,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { PAPER_ELEVATION, TARGET_TREE_WIDTH } from "../../constants";

import { RichTreeView } from "@mui/x-tree-view/RichTreeView";

import ChartTargetFilterSelection from "./ChartTargetFilterSelection";
import { useTranslation } from "react-i18next";

/**
 * Element showing selection tree based on inputs
 * @param {Function} objectHandler - Function that returns the object 'chartObject'
 * @param {Object} selectedCampus - Object with campus / user data
 * @param {Object} selectedCategory - Object with main category data
 * @param {Number} currentStep - Link update sequence. Listen for 2, ignore others
 * @param {Function} onUpdate - Reset the global step value to 0
 * @returns {JSX.Element} The rendered Autocomplete component
 */
const ChartTargetFilter = memo(
  ({
    objectHandler,
    selectedCampus,
    chartObject,
    selectedCategory,
    currentStep,
  }) => {
    // used to control the component
    const { selectedTargetId, selectedChartDef, treeData, allTargets } =
      chartObject;

    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery((theme) =>
      theme.breakpoints.between("sm", "md")
    );
    const isLargeTablet = useMediaQuery((theme) =>
      theme.breakpoints.between("md", "lg")
    );

    const [localState, setLocalState] = useState(() => {
      let val = [];
      if (selectedTargetId) {
        val = allTargets.filter((f) => selectedTargetId === f.id);
      }

      return {
        selectedTargetId: selectedTargetId,
        selectedChartDef: selectedChartDef,
        filteredTargets: val,
        internalStep: 0,
      };
    });

    // Update based on global state, if allTargets change
    useEffect(() => {
      if (selectedTargetId) {
        const val = allTargets.filter((f) => selectedTargetId === f.id);
        setLocalState((prevState) => ({
          ...prevState,
          selectedTargetId: selectedTargetId,
          selectedChartDef: selectedChartDef,
          filteredTargets: val,
          internalStep: 0,
        }));
      }
    }, [allTargets, selectedTargetId, selectedChartDef]);
    // Update based on local state, if allTargets change
    useEffect(() => {
      if (localState.selectedTargetId) {
        const val = allTargets.filter(
          (f) => localState.selectedTargetId === f.id
        );
        setLocalState((prevState) => ({
          ...prevState,
          selectedTargetId: localState.selectedTargetId,
          // selectedChartDef: selectedChartDef,
          filteredTargets: val,
          internalStep: 0,
        }));
      } else {
        setLocalState((prevState) => ({
          ...prevState,
          filteredTargets: [],
          internalStep: 0,
        }));
      }
    }, [localState.selectedTargetId, allTargets]);

    const handleTreeItemClick = useCallback(
      (itemId) => {
        const val = allTargets.filter((f) => itemId === f.id);

        setLocalState((prevState) => ({
          ...prevState,
          selectedTargetId: itemId,

          filteredTargets: val,
          internalStep: 0,
        }));
      },
      [allTargets]
    );

    // Memoize props for ChartTargetFilterSelection to prevent re-renders when selectedCategory changes
    const memoizedProps = useMemo(() => {
      return {
        // allTargets: data.allTargets,
        // selectedTreeTarget: localState.selectedTargetId,
        localState: localState,
        allTargets: allTargets,
        selectedCampus: selectedCampus,
        selectedCategory: selectedCategory,
        currentStep: currentStep,
        objectHandler,
      };
    }, [
      localState,
      allTargets,
      objectHandler,
      selectedCategory,
      currentStep,
      selectedCampus,
    ]);

    const width = isMobile
      ? "100%"
      : isTablet
      ? "100%"
      : `${TARGET_TREE_WIDTH}px`;
    return (
      <div
        style={{
          marginTop: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: width,
        }}
      >
        <Card
          elevation={PAPER_ELEVATION}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: isMobile ? "space-between" : "left",
            alignItems: "left",
            padding: "1rem",
            width: width,
            // maxHeight: isMobile ? '200px' :  isTablet ? '300px' : null,
            // overflowY: isMobile ? 'auto' : null,
          }}
        >
          {allTargets.length > 0 && treeData.flatIdList.length > 0 ? (
            <>
              <CardContent
                sx={{
                  padding: "0px",
                  paddingBottom: "16px",
                  maxHeight: isMobile
                    ? "200px"
                    : isTablet
                    ? "300px"
                    : isLargeTablet
                    ? "600px"
                    : "750px",
                  overflowY: "auto",
                }}
              >
                {/* <Box sx={{ minHeight: 40, minWidth: 200 }}> */}
                <RichTreeView
                  items={treeData.tree}
                  onItemClick={(event, itemId) => handleTreeItemClick(itemId)}
                  expandedItems={treeData.flatIdList}
                  selectedItems={
                    localState.selectedTargetId
                      ? [localState.selectedTargetId]
                      : []
                  }
                />
                {/* </Box> */}
              </CardContent>
              <CardActions
                sx={{
                  marginBottom: "0px",
                  borderTop: `1px solid ${theme.palette.divider}`,
                  padding: "0px",
                }}
              >
                <ChartTargetFilterSelection {...memoizedProps} />
              </CardActions>
            </>
          ) : (
            <Box sx={{ padding: 2, textAlign: "center" }}>
              <Typography variant="body1">{t("NO_DATA")}</Typography>{" "}
              {/* Provide feedback when no targets are present */}
            </Box>
          )}
        </Card>
      </div>
    );
  }
);

export default ChartTargetFilter;
