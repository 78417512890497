import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FeedbackIcon from "@mui/icons-material/FavoriteBorder";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import LanguageSelector from "../Accounts/LanguageSelector";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { HEADER_HEIGHT, MENU_ELEVATION } from "../../constants";
import ColorSettings from "../Accounts/ColorSettings";
import axios from "axios";

const Header = memo(({ setColorList, logout }) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const [isUserInPartnerGroup, setIsUserInPartnerGroup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);

    if (token) {
      const fetchUserInfo = async () => {
        const apiloggedUserUrl = `${process.env.REACT_APP_API_BASE_URL}/api/accounts/get-user-info/`;
        try {
          const response = await fetch(apiloggedUserUrl, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setLoggedInUserEmail(data.user_name);
          } else {
            console.error("Failed to fetch user info");
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      };
      fetchUserInfo();
    } else {
      console.error("No token found in localStorage");
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const apiCustomerUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/customer/`;
    const apiPartnerUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/partner-customers/`;

    // Fetch customer and partner data
    Promise.all([
      fetch(apiCustomerUrl, headers).then((res) => res.json()),
      fetch(apiPartnerUrl, headers).then((res) => res.json()),
    ])
      .then(([customerData, partnerData]) => {
        if (customerData.results && customerData.results.length > 0) {
          setCustomers(customerData.results[0]);
        }
        if (partnerData && partnerData.length > 0) {
          setPartners(partnerData);
          const isUserInGroup = partnerData.some((partner) =>
            partner.users.some((user) => user.email === loggedInUserEmail)
          );
          setIsUserInPartnerGroup(isUserInGroup);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [loggedInUserEmail]);

  const handleMenuOpen = (event) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    // localStorage.clear();
    setIsLoggedIn(false);
    logout();
    navigate("/login");
  };

  const Logos = () => {
    if (isUserInPartnerGroup) {
      return (
        <img
          src={partners[0]?.partner_icon}
          alt="Partner Logo"
          style={{
            marginRight: "30px",
            width: "auto",
            height: `calc(${HEADER_HEIGHT} - 20px )`,
          }}
        />
      );
    } else if (customers?.customer_icon) {
      return (
        <img
          src={customers?.customer_icon}
          alt="Customer Logo"
          style={{ width: "auto", height: `calc(${HEADER_HEIGHT} - 20px )` }}
        />
      );
    } else {
      // console.log("No icon available to display.");
      return null;
    }
  };

  return (
    <AppBar position="sticky" className="header">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "100%", widtch: "100%" }}
      >
        <Box display="flex">
          <Logos />
        </Box>

        <Box display="flex">
          <IconButton
            //edge="end"
            color="inherit"
            onClick={handleMenuOpen}
            size="small"
            className="header"
          >
            <AccountBoxIcon />
          </IconButton>
          {isLoggedIn && (
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              disableScrollLock
              elevation={MENU_ELEVATION}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <IconButton
                style={{ position: "absolute", top: "5px", right: "5px" }}
                className="onPaper"
                size="small"
                onClick={handleMenuClose}
              >
                <CloseIcon />
              </IconButton>
              <Container
                sx={{ marginTop: "10px", minWidth: "250px" }}
                style={{ paddingLeft: "1rem" }}
              >
                <Typography gutterBottom variant="h6" className="settings">
                  {t("APPEARANCE")}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                  ></Typography>
                </Typography>
              </Container>
              {/* <ColorSettings
                setColorList={setColorList}
                handleClose={handleMenuClose}
              /> */}
              <Container style={{ paddingLeft: "1rem" }}>
                <Typography gutterBottom variant="h6" className="settings">
                  {t("QUICK ACCESS")}
                </Typography>
              </Container>
              <LanguageSelector integration={"header"} />

              <MenuItem
                onClick={() => {
                  navigate("/contact"); // Navigate to the contact form page
                  handleMenuClose();
                }}
                className="settings"
              >
                <ListItemIcon className="settings">
                  <FeedbackIcon />
                </ListItemIcon>
                <Typography variant="subtitle1" className="settings">
                  {t("Contact Support")}
                </Typography>
              </MenuItem>

              {/* <MenuItem
                onClick={() => {
                  navigate("/settings");
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                {t("SETTINGS")}
              </MenuItem> */}

              {/* <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <FeedbackIcon />
                </ListItemIcon>
                {t("FEEDBACK")}
              </MenuItem> */}
              <MenuItem onClick={handleLogout} className="settings">
                <ListItemIcon className="settings">
                  <LogoutIcon />
                </ListItemIcon>
                <Typography variant="subtitle1" className="settings">
                  {t("LOGOUT")}
                </Typography>
              </MenuItem>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ marginTop: "0px" }}
              >
                &copy; {new Date().getFullYear()} Oxoia AG - (v2.2.5)
              </Typography>
            </Menu>
          )}
        </Box>
      </Box>
    </AppBar>
  );
});

export default Header;
