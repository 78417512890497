import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem, ListItemIcon, Typography } from "@mui/material";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { Translation } from "react-i18next";

const ColorSettings = ({ handleClose }) => {
  const navigate = useNavigate();

  return (
    <MenuItem
      style={{
        marginBottom: "1rem",
      }}
      className="settings"
      onClick={() => {
        navigate("/theme");
        handleClose();
      }}
    >
      <ListItemIcon className="settings">
        <FormatColorFillIcon />
      </ListItemIcon>
      <Typography variant="subtitle1" className="settings">
        <Translation>{(t) => <div>{t("COLORS")}</div>}</Translation>
      </Typography>
    </MenuItem>
  );
};

export default ColorSettings;
