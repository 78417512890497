import { createTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import darkScrollbar from "@mui/material/darkScrollbar";
import urbanist from "@fontsource/urbanist";
import "@fontsource/urbanist/400.css"; // Specify weight
import "@fontsource/urbanist/400-italic.css"; // Specify weight and style
import { HEADER_HEIGHT, BORDER_RADIUS } from "./constants";

export default function oxoia_theme(
  colors = [
    "#012A39",
    "#019C4B",
    "#FF0000",
    "#FF8500",
    "#BCC7D3",
    "#009C4A",
    "#ffffff",
    "#ffffff",
  ],
  mode = "light"
) {
  const { palette, breakpoints } = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: colors[0],
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: colors[1],
      },
      error: {
        main: colors[2],
      },
      warning: {
        main: colors[3],
      },
      info: {
        main: colors[4],
      },
      success: {
        main: colors[5],
      },
      background: {
        default: colors[6],
        paper: colors[7],
      },
      // text: {
      //     primary: colors[6],
      //     secondary: colors[7],
      //     disabled: colors[7],
      // },
    },
  });

  const fontFamily = "Urbanist, sans-serif";

  const theme = createTheme({
    palette: palette,

    // custom variables vor css
    cssVarPrefix: "ox",
    customVariables: {
      "--primary-color": palette.primary.main, // Example primary color value
      "--secondary-color": palette.secondary.main, // Example secondary color value
      "--font-family": fontFamily, // Example font family value
      "--font-size": "1rem", // Example font family value
      "--default-border-radius": `${BORDER_RADIUS}px`,
      // Define additional custom variable values here
    },

    // Define your theme options here
    typography: {
      fontFamily: fontFamily,
    },
    components: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [urbanist],
        },
        styleOverrides: {
          body: {
            ...darkScrollbar(),
            //color: `${palette.primary.contrastText}`,
            //fontFamily:"Urbanist","Roboto","Helvetica"
            //background: `linear-gradient(to right, ${palette.primary.main}, ${palette.secondary.main})`,

            // titles must be on backgroud
            "& h1": {
              color: `${palette.background.contrastText}`,
            },
          },
        },
      },

      // Name of the component
      MuiButtonGroup: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS

            height: "30px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "medium",
            background: palette.secondary.dark,
            color: palette.secondary.contrastText,
            textTransform: "none",
            borderRadius: `${BORDER_RADIUS}px`,
            paddingTop: "0px",
            paddingBottom: "0px",
            height: "28px",
            minWidth: "36px",
            paddingLeft: "12px",
            paddingRight: "12px",
            "&.Mui-disabled": {
              opacity: 0.7,
              background: palette.info.main,
            },
            "&:hover": {
              backgroundColor: palette.secondary.light,
              color: palette.secondary.contrastText,
              boxShadow: `0 0 10px ${palette.primary.contrastText}, 0 0 20px ${palette.primary.contrastText}`,
            },
            //borderRadius: '20px', // => may move this to login/register
            "&.login": {
              // Additional styles for the subclass
              // Example:
              borderRadius: "20px",
            },
            "&.popup": {
              // Additional styles for the subclass
              // Example:
              background: palette.secondary.main,
              color: palette.secondary.contrastText,
            },
            "&.workspace": {
              minWidth: "64px",
              "&:hover": {
                backgroundColor: palette.secondary.light,
              },
              "&:focus": {
                outline: `2px solid ${palette.secondary.dark}`,
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            borderRadius: BORDER_RADIUS,
            border: `1px solid ${alpha(
              palette.getContrastText(palette.background.default),
              0.05
            )}`,
            color: alpha(
              palette.getContrastText(palette.background.default),
              0.5
            ),
            padding: "4px",
            marginRight: "3px",
            "&.header": {
              marginRight: "0px",
              color: palette.primary.contrastText,

              border: `1px solid ${palette.primary.main}`,
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                backgroundColor: palette.primary.main,
                color: palette.primary.contrastText,
                boxShadow: `0 0 10px ${palette.primary.contrastText}, 0 0 20px ${palette.primary.contrastText}`,
              },
            },
            "&.sideMenu": {
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.1s ease",
              "&:hover": {
                backgroundColor: alpha(palette.primary.light, 0.3),
                color: palette.primary.contrastText,
                boxShadow: `0 0 10px ${alpha(
                  palette.primary.light,
                  0.7
                )}, 0 0 20px ${alpha(palette.primary.light, 0.7)}`,
              },
            },
            "&.onPaper": {
              marginRight: "0px",
              color: alpha(
                palette.getContrastText(palette.background.paper),
                0.5
              ),

              border: `1px solid ${alpha(
                palette.getContrastText(palette.background.paper),
                0.05
              )}`,
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                // backgroundColor: palette.primary.light,
                color: palette.getContrastText(palette.background.paper),
                // boxShadow: `0 0 10px ${palette.primary.contrastText}, 0 0 20px ${palette.primary.contrastText}`
              },
            },
            "&.settings": {
              marginRight: "0px",
              color: palette.primary.contrastText,

              border: `1px solid ${palette.primary.light}`,
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                // backgroundColor: palette.primary.light,
                color: palette.primary.contrastText,
                // boxShadow: `0 0 10px ${palette.primary.contrastText}, 0 0 20px ${palette.primary.contrastText}`
              },
            },
            "&.loginScreen": {
              marginRight: "0px",
              color: palette.getContrastText(palette.background.default),

              "&:hover": {
                backgroundColor: alpha(
                  palette.getContrastText(palette.background.default),
                  0.15
                ),
                boxShadow: `0 0 10px ${alpha(
                  palette.getContrastText(palette.background.default),
                  0.15
                )}, 0 0 20px ${alpha(
                  palette.getContrastText(palette.background.default),
                  0.15
                )}`,
              },
            },
            "&.headerMenu": {
              // Additional styles for the subclass
              // Example:

              backgroundColor: palette.secondary.dark,
              color: palette.secondary.contrastText,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: palette.primary.dark,
            // transition: 'box-shadow 0.3s ease',
            "&.sideMenu": {
              transition: "box-shadow 0.3s ease",
              margin: "5px",
              "&:hover": {
                backgroundColor: alpha(palette.primary.light, 0.15),
                // backgroundColor: alpha(palette.getContrastText(palette.primary.light), 0.15),
              },
            },
            "&.settings": {
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                backgroundColor: alpha(
                  palette.getContrastText(palette.background.paper),
                  0.15
                ),
              },
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          // root: {
          //     backgroundColor: palette.secondary.dark,
          //     padding: '0px', // Example padding
          //     margin: 1
          // },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition
            backgroundColor: palette.secondary.dark,

            borderRadius: `${BORDER_RADIUS}px`,
            "&:hover": {
              backgroundColor: "#e0e0e0", // Change background on hover
              boxShadow: "0 0 10px rgba(255, 165, 0, 0.8)", // Glow effect
            },
            "&:active": {
              backgroundColor: "#d0d0d0", // Darker background when active
            },
          },
          dense: {
            borderRadius: `${BORDER_RADIUS}px`,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: "bold",
          },
          secondary: {
            color: "#888",
          },
          root: {
            //borderColor: palette.secondary.dark,
            //background: palette.secondary.main,
            color: palette.primary.dark,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          // Name of the slot
          root: {
            //borderColor: palette.secondary.dark,
            //background: palette.secondary.main,
            "&.sideMenu": {
              color: palette.text.primary,
            },
            "&.settings": {
              color: palette.getContrastText(palette.background.paper),
              "&:hover": {
                // backgroundColor: alpha(palette.primary.light, 0.15),
                // backgroundColor: alpha(palette.getContrastText(palette.primary.light), 0.15),
              },
              // '&:hover': {
              //     boxShadow: '0 0 10px rgba(25, 118, 210, 0.7), 0 0 20px rgba(25, 118, 210, 0.5)', // Glow effect
              //     backgroundColor: 'rgba(25, 118, 210, 0.1)', // Optional: Light background on hover
              // },
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          // Name of the slot
          root: {
            boxShadow: "var(--ox-shadows-3)",
            background: palette.primary.main,
            color: palette.primary.contrastText,
          },
          // used for menu left side
          paper: {
            borderRight: `1px solid ${alpha(palette.primary.dark, 0.3)}`,
            background: palette.background.default,
            color: palette.text.primary,
            // borderColor: theme.palette.primary.dark,
            // boxShadow: '4px 3px 3px rgba(0, 0, 0, 0.5)',
            borderRadius: "0px",
            boxSizing: "border-box",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          // Name of the slot
          root: {
            color: palette.background.contrastText,
            //color: palette.primary.contrastText,
            "&.loginLanguage": {
              color: palette.secondary.contrastText,
            },
            "&.settings": {
              color: palette.getContrastText(palette.background.paper),
            },
            "&.sideMenu": {
              color: palette.primary.contrastText,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderColor: palette.getContrastText(palette.background.default),
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            background: palette.background.default,
            borderRadius: `${BORDER_RADIUS}px`,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          // root: {

          //     background:"red",
          // },
          // input: {

          //     paddingTop: '6px',
          //     paddingBottom: '6px',
          //     //height:'20px', => add separate class
          //     ':-internal-autofill-selected': {
          //         backgroundColor: '#000 !important', // Override user agent styles with !important
          //         // Add any other custom styles here
          //     },
          // },

          root: {
            color: palette.getContrastText(palette.background.default),
          },
          inputAdornedEnd: {
            color: palette.getContrastText(palette.background.default),
          },
          input: {
            paddingTop: "6px",
            paddingBottom: "6px",
            color: palette.getContrastText(palette.background.default),
          },
          adornedStart: {
            color: palette.getContrastText(palette.background.default),
          },
          notchedOutline: {
            color: palette.getContrastText(palette.background.default),
            borderColor: palette.getContrastText(palette.background.default),
            borderRadius: `${BORDER_RADIUS}px`,
            borderWidth: "1px",
            "&:hover": {
              borderColor: "blue",
            },
            "&:focus": {
              borderColor: "red",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          // Name of the slot
          root: {
            backgroundColor: palette.background.default,
            color: palette.getContrastText(palette.background.default),
            padding: 0,
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          // Name of the slot
          content: {
            borderRadius: `${BORDER_RADIUS}px`,

            // padding: 0,
            "&.Mui-selected": {
              backgroundColor: alpha(palette.secondary.main, 0.5),
              color: palette.getContrastText(
                alpha(palette.secondary.main, 0.5)
              ),
            },
            "&:hover": {
              backgroundColor: palette.action.hover,
              color: palette.text.primary,
            },
          },
          label: {
            fontSize: "0.875rem",
            [breakpoints.up("sm")]: {
              fontSize: "1rem",
            },
            [breakpoints.up("md")]: {
              fontSize: "1rem",
            },
            "&.Mui-selected": {
              // borderColor: palette.secondary.main,
              // border: `1px solid ${palette.secondary.main}`,

              backgroundColor: alpha(palette.secondary.main, 0.5),
              color: palette.getContrastText(
                alpha(palette.secondary.main, 0.5)
              ),
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          // Name of the slot

          label: {
            fontSize: "0.875rem",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "6px",
            paddingRight: "6px",

            "&:hover": {
              backgroundColor: palette.action.hover,
              color: palette.getContrastText(palette.action.hover),
            },
            [breakpoints.up("sm")]: {
              fontSize: "1rem",
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "6px",
              paddingRight: "6px",
            },
            [breakpoints.up("md")]: {
              fontSize: "1rem",
            },
          },
          root: {
            height: "auto",
            background: palette.secondary.main,
            color: palette.secondary.contrastText,

            borderRadius: `${BORDER_RADIUS}px`,
            "@media (max-width:800px)": {
              height: "20px",
            },
            "@media (max-width:600px)": {
              height: "20px",
            },
          },
          "&.mobile": {
            // Additional styles for the subclass
            // Example:
            fontSize: "0.875rem",
            paddingTop: "0px",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1rem",
            "&.header": {
              // Additional styles for the subclass
              // Example:
              padding: "0px",
              margin: "0px",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&.header": {
              //backgroundColor: palette.background.paper,
              //boxShadow: "none",
              boxShadow: "4px 0px 15px rgba(0, 0, 0, 0.5)",
              borderRadius: "0px",
              position: "sticky",
              top: 0,
              zIndex: 1000,
              // borderBottom: "5px",
              backgroundColor: palette.primary.dark,
              borderColor: palette.primary.dark,
              paddingRight: "1rem",
              paddingLeft: "1rem",
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              width: "100%",
              // display: 'flex',
              // flexDirection: 'row',
              // justifyContent: 'space-between',
              // alignItems: "center",
              //color: palette.text.primary,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            backgroundColor: palette.background.default,
            color: palette.getContrastText(palette.background.default),
            "&:hover": {
              backgroundColor: "lightgray", // Change background color on hover
              color: "black",
            },
            "&.Mui-selected": {
              backgroundColor: "blue", // Change background color when selected
              color: "white",
            },
          },
          input: {
            backgroundColor: palette.background.default,
            color: palette.getContrastText(palette.background.default),
            "&::placeholder": {
              color: "gray", // Change placeholder color
            },
          },
          clearIndicator: {
            color: alpha(
              palette.getContrastText(palette.background.default),
              0.5
            ),
            "&:hover": {
              color: "darkred", // Change color on hover
            },
          },
          loading: {
            color: "blue", // Change loading spinner color
          },
          paper: {
            backgroundColor: palette.background.default,
            color: palette.getContrastText(palette.background.default),
            boxShadow: "var(--ox-shadows-3)",
            "&:hover": {
              boxShadow: `0 0 10px ${alpha(
                palette.getContrastText(palette.background.default),
                0.15
              )}, 0 0 20px ${alpha(
                palette.getContrastText(palette.background.default),
                0.15
              )}`,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.getContrastText(palette.background.default),
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: `${BORDER_RADIUS}px`,
            "&.login": {
              // Additional styles for the subclass
              // Example:
              borderRadius: `${BORDER_RADIUS}px`,
            },
            //   '&.header': {
            //     backgroundColor: theme.palette.background.paper,
            //     boxShadow: "none",
            //     borderRadius: "0px",
            //     paddingRight: "16px",
            //     paddingLeft: "16px",
            //     color: theme.palette.text.primary,
            //   },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: `${alpha(
              palette.getContrastText(palette.background.default),
              0.2
            )}`,
          },
        },
      },
    },
  });

  return theme;
}
