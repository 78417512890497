import React, { useState, useMemo } from "react";
import { useTheme, Popover, useMediaQuery, IconButton } from '@mui/material';
import RangeCalendarView from './RangeCalendarView'

const RangePopover = ({ openStateHandler, updateValues, actualValues,rangeHandler, actualRange, dateString }) => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        const isOpen = anchorEl === event.currentTarget;
        setAnchorEl(isOpen ? null : event.currentTarget);
        openStateHandler(!isOpen);
    };

    // Update all values if closing popover
    const handleClose = () => {
        setAnchorEl(null);
        openStateHandler(false);
    };
    
    const open = Boolean(anchorEl);
    const buttonStyle = useMemo(() => ({
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
        fontSize: isMobile ? '0.75rem' : isTablet ? '0.85rem' : '1rem',
    }), [isMobile, isTablet, theme.palette.text.primary]);

    const popoverPaperStyles = useMemo(() => ({
        zIndex: 1600,
        marginTop: "1.5rem",
        width: isMobile ? '90%' : 'auto',
    }), [isMobile]);

    

    return (
        <>
            <IconButton style={buttonStyle} onClick={handleClick}>
                {dateString}
            </IconButton>

            <Popover
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                anchorEl={anchorEl}
                id={open ? 'range-popper' : undefined}
                slotProps={{
                    paper: {
                        sx: popoverPaperStyles,
                    },
                }}
            >
                <RangeCalendarView popper={open} rangeHandler={rangeHandler} actualRange={actualRange} updateValues={updateValues} actualValues={actualValues} />
            </Popover>
        </>
    );
}

export default RangePopover;