
import uPlot from 'uplot';
import { UPLOT_DAYS, UPLOT_DAYS_SHORT, hotTranslate, UPLOT_MONTHS_SHORT } from '../../constants';
import { hexToRgba, rgbToHex, generateColorPalette } from './chartFuncs'
import { alpha } from '@mui/material/styles';

const AXIS_GAP_BOOL = 1.2
const AXIS_GAP_PERCENT = 10
const NO_VALUE = -99
const NO_VALUE_BOOL = 0.000009


// Based on example from https://codesandbox.io/p/sandbox/uplot-react-6ykeb

const stringify = (obj) =>
    JSON.stringify(obj, (key, value) =>
        typeof value === 'function' ? value.toString() : value
    );

// Compare to uplot options (uPlot.Options) if equivalent. Return a state
const updateRequired = (_lhs, _rhs) => {
    const { width: lhsWidth, height: lhsHeight, ...lhs } = _lhs;
    const { width: rhsWidth, height: rhsHeight, ...rhs } = _rhs;

    let state = 'keep';
    if (lhsHeight !== rhsHeight || lhsWidth !== rhsWidth) {
        state = 'update';
    }
    if (Object.keys(lhs).length !== Object.keys(rhs).length) {
        return 'create';
    }
    for (const k of Object.keys(lhs)) {
        if (stringify(lhs[k]) !== stringify(rhs[k])) {
            state = 'create';
            break;
        }
    }
    return state;
};

// Compare to uplot dat (uPlot.AlignedData) if equivalent. Return a Bool
const doesMatch = (lhs, rhs) => {
    if (lhs.length !== rhs.length) {
        return false;
    }
    return lhs.every((lhsOneSeries, seriesIdx) => {
        const rhsOneSeries = rhs[seriesIdx];
        if (lhsOneSeries.length !== rhsOneSeries.length) {
            return false;
        }
        return lhsOneSeries.every((value, valueIdx) => value === rhsOneSeries[valueIdx]);
    });
};

/**
 * Set the plots time scale with time zone (all timestamps from database are UTC). Merge with existing object.
 * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @param {String} tz - TZ indentifier
 *  */
const timeZoneData = (tz) => {
    return { tzDate: ts => uPlot.tzDate(new Date(ts * 1e3), tz) }
};

/**
 * Sync cursor of multiple plots. Merge with existing object.
 * See https://github.com/leeoniya/uPlot/blob/master/demos/timezones-dst.html
 * @param {Number} tz - Any number
 *  */
const syncCursers = (key) => {
    return { cursor: { sync: { key: key, setSeries: true } } }
};

/**
 * Format dates for Weekdays
 *  */
const formatDates = () => {
    const deDays = {
        WWWW: hotTranslate(UPLOT_DAYS).map(m => m.text),
        WWW: hotTranslate(UPLOT_DAYS_SHORT).map(m => m.text),
        MMM: hotTranslate(UPLOT_MONTHS_SHORT).map(m => m.text)
    }
    return { fmtDate: tpl => uPlot.fmtDate(tpl, deDays) }
};

/**
 * Retrieves an array of indices corresponding to points with boolean values 
 * in the provided chart definitions. This function scans the 'kind' property 
 * of the chart definitions and collects the indices of all entries that 
 * are labeled as "Bool". It effectively filters out non-boolean entries, 
 * allowing for easy identification of boolean series in the chart.
 * 
 * @param {Object} _chartDefs - The metadata for the chart, which includes 
 *                              a 'kind' property that specifies the type of each series.
 * @returns {Array<number>} - An array of indices where the series type is "Bool".
 */
const boolIndices = (_chartDefs) => {
    return _chartDefs.map(k => k.kind).flat()
        .map((value, index) => (value === "Bool" ? index : -1)) // Map to index or -1
        .filter(index => index !== -1); // Filter out non-boolean indices
};


/**
 * Define axes format for time and all y-series with unique units
 * @param {String} _chartDefs - Chart meta data
 *  */
const formatAxes = (_chartDefs, theme) => {

    const xAxis = () => {
        return [{
            // space: 100,
            // height: 60,
            //label: "Population",
            //show:true,
            //labelFont: "bold 12px Urbanist",
            font: "12px Urbanist, sans-serif",
            side: 2,
            gap: 10,
            //stroke: "var(--ox-palette-secondary-main)", // "var(--ox-palette-primary-contrastText)",
            // class: "x-time",
            incrs: [
                // minute divisors (# of secs)
                1,
                5,
                10,
                15,
                30,
                // hour divisors
                60,
                60 * 5,
                60 * 10,
                60 * 15,
                60 * 30,
                // day divisors
                3600,
                3600 * 4,
                3600 * 12,
                3600 * 24,
                3600 * 24 * 28,
                3600 * 24 * 365,
                // ...
            ],
            // see here: https://github.com/leeoniya/uPlot/blob/master/src/fmtDate.js
            values: [
                // tick incr     default      year      month    day    hour     min   sec   mode
                [3600 * 24 * 365, "{YYYY}", null, null, null, null, null, null, 1],
                [3600 * 24 * 28, "{MMM}", "\n{YYYY}", null, null, null, null, null, 1],
                [3600 * 12, "{WWW}, {DD}.{MM}", "\n{YYYY}", null, null, null, null, null, 1],
                [3600 * 4, "{HH}:{mm}", null, null, "\n{WWW}, {DD}.{MM}", null, null, null, 1],
                [3600, "{HH}:{mm}", "\n{DD}.{MM}.{YY}", null, "\n{DD}.{MM}", null, null, null, 1],
                [60, "{HH}:{mm}", "\n{DD}.{MM}.{YY}", null, "\n{DD}.{MM}", null, null, null, 1],
                [1, ":{ss}", "\n{DD}.{MM}.{YY} {HH}:{mm}", null, "\n{DD}.{MM} {HH}:{mm}", null, "\n{HH}:{mm}", null, 1],
                [0.001, ":{ss}.{fff}", "\n{DD}.{MM}.{YY} {HH}:{mm}", null, "\n{DD}.{MM} {HH}:{mm}", null, "\n{HH}:{mm}", null, 1],
            ],
            grid: {
                //color: "red",
                stroke: theme.palette.secondary.light,
                width: 0.5,
                show: true,
            },
            ticks: {
                show: true,
                stroke: theme.palette.secondary.light,
                width: 1,
                dash: [],
                size: 6,
            },
            //  splits:
        }]
    }


    // Create axis for units and for bool
    const yAxes = (_defs) => {
        const units = _defs.map(m => m.units).flat()
        const axes = [];
        const processedUnits = new Set(); // To keep track of already processed units

        const boolIndicesArr = boolIndices(_defs)
        const indices = boolIndicesArr.map((_, index) => index);

        for (let i = 0; i < units.length; i++) {
            const unit = units[i];

            // Check if the unit is already processed
            if (unit && !processedUnits.has(unit)) {
                // no bools here, as no unit
                processedUnits.add(unit); // Add the unit to the set

                axes.push({
                    scale: units[i] === "%" ? '%' : 'y',
                    side: units[i] === "%" ? 1 : 3, // 1: right, 3: left
                    grid: { show: true },
                    values: (u, splits, axisIdx, valAxisx) => {

                        // const step = splits.length > 0 ? (splits[splits.length - 1] - splits[splits.length - 2]) : 1

                        return splits.map((yAxisVal, splitIndex) => {
                            // Add 10% additional space per bool in range
                            // Move description up

                            if (splitIndex in indices && indices.indexOf(splitIndex) !== indices.length - 1) {
                                return null
                            }
                            else {
                                if (units[i] === "%" && yAxisVal < 0) return null
                                else return `${yAxisVal.toFixed(0)} ${unit}`
                            }

                        });
                    },
                });
            } else {
                // no unit = null

                if (_chartDefs.map(k => k.kind).flat()[i] === "Bool" && !processedUnits.has(unit)) {
                    processedUnits.add(unit);
                    // axes.push({
                    //     scale:  "bool",
                    //     side: 1,
                    //     grid: { show: false },
                    //     values: (self, splits) => {
                    //         return splits.map(rawValue => {
                    //             // Modify value here
                    //             console.log("bool axis bool", rawValue, splits)

                    //             return "";
                    //         });
                    //     },
                    // });
                }
            }
        }
        return axes;
    };
    const all = [

        ...xAxis(),
        ...yAxes(_chartDefs)

    ]

    return { axes: all }
};
/**
 * Define series and legend format for time and all y-series with unique units
 * Series with hisMode="cov" require step functions
 * @param {Object} _chartDefs - Chart meta data
 *  */
const defineYSeries = (_chartDefs, theme) => {
    const series = []
    const points = _chartDefs.map(k => k.points).flat()
    const navName = _chartDefs.map(k => k.navName).flat()
    const units = _chartDefs.map(k => k.units).flat()
    const kind = _chartDefs.map(k => k.kind).flat()
    // const color = _chartDefs.map(k=> k.color).flat()

    // Use js color palette
    const color = generateColorPalette(rgbToHex(theme.palette.primary.light), rgbToHex(theme.palette.secondary.light), points.length);

    const hisMode = _chartDefs.map(k => k.hisMode).flat()
    const chartType = [...new Set(_chartDefs.map(k => k.chartType))];

    const boolIndicesArrPlus = boolIndices(_chartDefs).map(v => v + 1)

    for (let i = 0; i < points.length; i++) {
        // console.log("bool", _chartDefs.color)


        series.push({
            // in-legend display
            label: navName[i],
            // set units if available (u, raw, sidx, didx) => {...}
            // for legend
            value: (self, rawValue, seriesIdx) => {
                if (rawValue === null) return null
                else if (rawValue === NO_VALUE || rawValue === NO_VALUE_BOOL) return "NA"
                else {
                    if (units[i] === null) {
                        if (kind[i] === "Bool") {
                            const boolIndex = boolIndicesArrPlus.indexOf(seriesIdx);
                            return rawValue === boolIndex * -AXIS_GAP_BOOL ? false : true
                        } else return rawValue
                    }
                    else return `${Number.parseFloat(rawValue).toFixed(2)} ${units[i]}`
                }
            },
            // Function define fill area
            fillTo: (u, seriesIdx, min, max, dir) => {
                if (kind[i] === "Bool") {
                    return min
                } else return 0
            },

            points: { show: false },
            stroke: (color[i] === undefined) ? "red" : color[i],

            width: kind[i] === "Bool" ? 2 : 2,
            spanGaps: false,
            fill: (chartType[0] === "area-chart" || kind[i] === "Bool") ? hexToRgba(color[i], 0.5) : null,
            scale: units[i] === "%" ? '%' : (kind[i] === "Bool" ? "bool" : 'y'),
            //step series
            paths: hisMode[i] === "cov" ? uPlot.paths.stepped({ align: 1 }) : null,
        })
    }
    return series
}

/**
 * Toggles the visibility of a specific data series in a uPlot chart based on user interaction. 
 * This function allows for isolating a series when the Ctrl (or Command) key is held down, 
 * meaning that if any other series is visible, only the selected series will be shown. 
 * If the key is not held, it simply toggles the visibility of the selected series.
 * 
 * @param {Event} e - The event object representing the user interaction (e.g., mouse click).
 * @param {Object} obj - An object containing relevant information for toggling the series.
 * @param {Object} obj.uplot - The uPlot instance containing the chart data and configuration.
 * @param {string} obj.label - The label of the series being toggled (not used directly in this function).
 * @param {number} obj.seriesIndex - The index of the series to toggle within the uPlot instance.
 */
const toggleData = (e, obj) => {
    const { uplot, seriesIndex } = obj;
    const serie = uplot.series[seriesIndex]; // Retrieve the series object based on the index

    console.log("TOGGLE", uplot, serie, e); // Log the toggle action for debugging

    // Check if the Ctrl or Command key is pressed
    if (e.ctrlKey || e.metaKey) {
        // Determine if any other series is currently shown
        const isolate = uplot.series.some((s, i) => i > 0 && i !== seriesIndex && s.show);

        // Toggle visibility of series based on isolation condition
        uplot.series.forEach((s, i) => {
            if (i > 0) {
                uplot.setSeries(i, isolate ? { show: i === seriesIndex } : { show: true }, true, false);
            }
        });
    } else {
        // Toggle the visibility of the selected series
        uplot.setSeries(seriesIndex, { show: !serie.show }, true, false);
    }
};


/**
 * Create a uplot chart
 * 
 * @param {Object} _chartDefs - Chart meta data
 *  */
const makeChart = (params) => {
    const { chartDefs, origData, chartDiv, opts, blockSize, divK, dateWithTimestamps, theme } = params
    // More opts
    const modOpts = {
        ...opts,
        scales: {
            x: {
                time: true,
            },
            ...defineYScales(chartDefs),
        },
        plugins: [
            wheelZoomPlugin({ factor: 0.75 }),
            legendAndTooltipPlugin(
                {
                    orgiData: origData,
                    enableToolTip: blockSize.parent.drawerWidth === 0 ? false : true,
                    enableSideLabel: true,
                    blockSize: blockSize,
                    key: divK,
                    toggle: toggleData,
                    style: {
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.secondary,
                        border: `1px solid ${alpha(theme.palette.primary.dark, 0.95)}`,
                        boxShadow: "var(--ox-shadows-13)",
                    }
                }
            ),
            drawNightShades({ color: alpha(theme.palette.info.light, 0.17), dateWithTimestamps: dateWithTimestamps, theme: theme }),
            touchZoomPlugin(),

        ],
    }

    // Create plot
    let plot = new uPlot(modOpts, mapData(origData, chartDefs), chartDiv);

    return plot
}

/**
 * Defines the scale formats and ranges for all Y-series in the chart based on the provided chart metadata. 
 * This function calculates the appropriate ranges for different types of Y-axes, including standard, 
 * percentage, and boolean axes. It adjusts the min and max values based on the number of boolean series 
 * and their associated gaps to ensure proper spacing and visibility on the chart.
 * 
 * @param {Object} _chartDefs - The metadata for the chart, which includes information about the series 
 *                              and their types, used to determine the number of boolean series.
 * @returns {Object} - An object containing the scale definitions for the Y-axes, including range functions 
 *                    for standard, percentage, and boolean scales.
 */
const defineYScales = (_chartDefs) => {
    const scales = {};
    const boolIndicesArr = boolIndices(_chartDefs); // Get indices of boolean series

    // Define the Y-axis scales
    scales.y = {
        range: (u, min, max) => {
            const axisGap = u.axes.find(f => f.scale === "y").gap; // Find the gap for the Y-axis
            // Extend the range by the number of boolean series times the gap
            return [
                Math.min(min - boolIndicesArr.length * axisGap, min - axisGap),
                max + axisGap
            ];
        },
    };

    scales['%'] = {
        range: (u, min, max) => {
            // Adjust the range for percentage scale
            return [
                Math.min(min - boolIndicesArr.length * AXIS_GAP_PERCENT, min - AXIS_GAP_PERCENT / 2),
                max + AXIS_GAP_PERCENT / 2
            ];
        },
    };

    scales.bool = {
        range: (u, min, max) => {
            // Set the range for boolean series to ensure proper positioning
            return [
                -0.5 - (boolIndicesArr.length - 1) * AXIS_GAP_BOOL,
                15
            ];
        },
    };

    return scales; // Return the defined scales
}


/**
 * Recalculates the position of data points on the canvas based on specified chart definitions. 
 * This function modifies the input data array by adjusting the values of boolean series 
 * to ensure they are displayed correctly on the chart. If a series is identified as a boolean 
 * series, its values are adjusted by a defined gap, while non-boolean series remain unchanged. 
 * The function handles cases where the input data may contain a special value indicating 
 * that the data point should not be displayed.
 * 
 * @param {Array} _data - An array of data series, where each series is an array of values.
 * @param {Object} _chartDefs - An object containing definitions for the chart, 
 *                              including information about which series are boolean.
 * @returns {Array} - A new array of data series with modified values for boolean series.
 */
const mapData = (_data, _chartDefs) => {
    const boolIndicesArrPlus = boolIndices(_chartDefs).map(v => v + 1);

    // Map through each data series and modify values based on boolean indices
    const dataWithModifications = _data.map((vals, seriesIdx) => {
        const boolIndex = boolIndicesArrPlus.indexOf(seriesIdx);
        if (boolIndex === -1) return vals; // Return original values if not a boolean series

        // Adjust values for boolean series
        return vals.map(val => {
            return val === NO_VALUE ? NO_VALUE_BOOL : val + boolIndex * -AXIS_GAP_BOOL;
        });
    });

    return dataWithModifications; // Return the modified data array
}

/**
 * Extracts the RGBA color value from a given CSS color string. 
 * This function uses a regular expression to match and capture 
 * the red, green, blue, and optional alpha components of the color. 
 * If the input string does not match the expected format, 
 * the function returns null.
 * 
 * @param {string} colorString - A string representing a CSS color, 
 *                               which can be in the format of 'rgb(r, g, b)' 
 *                               or 'rgba(r, g, b, a)'.
 * @returns {string|null} - Returns the extracted color in 'rgba(r, g, b, a)' format 
 *                          or null if the input is invalid or does not match the format.
 */
const extractColorFromStyle = (colorString) => {
    const rgbRegex = /rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)(?:\s*,\s*(\d*\.?\d+))?\s*\)/;

    if (!colorString) return null

    // Execute the regex on the provided style string
    const match = colorString.match(rgbRegex);

    // If a match is found, return the color
    if (match) {
        const r = match[1];
        const g = match[2];
        const b = match[3];
        const a = match[4] !== undefined ? match[4] : 1; // Default alpha to 1 if not present
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }

    return null;
}

/**
 * Extracts data from a uPlot chart and updates an external legend element 
 * based on the provided configuration. This function retrieves series data 
 * from the chart, constructs legend entries, and appends them to a specified 
 * external div identified by the 'key' parameter. It also handles the 
 * visibility of legend entries based on the workspace width and split settings.
 * 
 * @param {Object} uplot - The uPlot instance containing the chart data.
 * @param {string} key - The identifier for the external legend div.
 * @param {Object} blockSize - Configuration object containing sizing parameters for the legend.
 * @param {Object} blockSize.legend - Properties related to the legend's dimensions and margins.
 * @param {number} blockSize.legend.legendWidth - The total width of the legend.
 * @param {number} blockSize.legend.legendMarginPerPoint - The margin between legend entries.
 * @param {number} blockSize.legend.legendHeightPerPoint - The height of each legend entry.
 * @param {number} blockSize.legend.legendWidthLabel - The width allocated for the label in the legend.
 * @param {number} blockSize.legend.legendWidthValue - The width allocated for the value in the legend.
 * @param {Object} blockSize.parent - Properties related to the parent container of the legend.
 * @param {number} blockSize.parent.workspaceWidth - The width of the workspace where the legend is displayed.
 * @param {number} blockSize.parent.split - A threshold value to determine layout behavior.
 */
const extractAndUpdate = (uplot, key, blockSize, toggle) => {
    const { legend, parent } = blockSize
    const { legendWidth, legendMarginPerPoint, legendHeightPerPoint, legendWidthLabel, legendWidthValue, legendFontSize } = legend
    const { workspaceWidth, split } = parent

    // Extract values from div
    const extractedValues = Array.from(uplot.root.querySelectorAll('tr.u-series')).map((row, i) => {
        const label = row.querySelector('.u-label').textContent.trim();
        const value = row.querySelector('.u-value').textContent.trim();
        const markerStyle = row.querySelector('.u-marker').getAttribute('style');
        return { label, value, markerStyle, seriesIndex: i };
    });

    // External legend div
    const extLegend = document.getElementById(`${key}-legend`);
    if (!extLegend) return null; // Early return if not available

    extLegend.innerHTML = '';

    const createLegendEntry = ({ label, value, markerStyle, seriesIndex }) => {
        const color = markerStyle ? extractColorFromStyle(markerStyle) : null;

        // Create a new div for the legend entry
        const legendEntry = document.createElement('div');
        legendEntry.className = 'legend-entry'; // Use a CSS class for styling?
        Object.assign(legendEntry.style, {
            boxSizing: 'border-box',
            display: workspaceWidth < split ? 'none' : 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)',
            padding: `${legendMarginPerPoint}px`,
            marginBottom: `${legendMarginPerPoint}px`,
            height: `${legendHeightPerPoint}px`,
            width: `${legendWidth}px`,
        });


        // Add click event listener
        legendEntry.addEventListener("click", (e) => {
            toggle(e, { uplot, label, value, seriesIndex });
        });

        // Create a marker div (not shown)
        const markerDiv = document.createElement('div');
        markerDiv.className = 'u-marker-ext';
        markerDiv.setAttribute('style', markerStyle);
        Object.assign(markerDiv.style, {
            width: '10px',
            height: '3px',
            border: '3px solid black',
            marginRight: '5px',
            display: 'none'
        });

        // Styles for label and value
        const componentStyle = {
            fontWeight: 'bold',
            fontSize: `${legendFontSize}px`,
            color: color,
            height: `${legendHeightPerPoint}px`,
        }

        // Create a label div
        const labelDiv = document.createElement('div');
        // labelDiv.className = 'u-label';
        labelDiv.textContent = label;
        Object.assign(labelDiv.style, componentStyle, {
            width: `${legendWidthLabel}px`
        });

        // Create a value div
        const valueDiv = document.createElement('div');
        // valueDiv.className = 'u-value';
        valueDiv.textContent = (seriesIndex === 0 && value === "1.1.1970, 01:00:00") ? "--" : value;
        Object.assign(valueDiv.style, componentStyle, {
            textAlign: 'right',
            width: `${legendWidthValue}px`
        });

        // Append marker, label, and value divs to the legend entry
        legendEntry.append(markerDiv, labelDiv, valueDiv);

        return legendEntry;
    };
    
    // Main loop to create legend entries
    if (legendWidth > 0) {
        extractedValues.forEach((entry) => {
            const legendEntry = createLegendEntry({ ...entry });
            extLegend.appendChild(legendEntry);
        });
    }

}

/**
 * A plugin for managing a tooltip and legend within a charting library. 
 * The tooltip can be enabled or disabled based on user preference, 
 * while the legend can be linked to an external HTML element specified by the 'key' parameter.
 * 
 * @param {Object} options - Configuration options for the plugin.
 * @param {boolean} options.toggle - A flag to toggle the visibility of the legend.
 * @param {boolean} options.enableToolTip - A flag to enable or disable the tooltip functionality.
 * @param {number} options.blockSize - The size of the blocks used in the legend.
 * @param {boolean} options.enableSideLabel - A flag to enable side labels in the legend.
 * @param {string} options.key - The selector for the external div where the legend will be mapped.
 * @param {string} options.className - Optional CSS class to apply to the legend element.
 * @param {Object} options.style - Additional inline styles to apply to the legend element.
 */
const legendAndTooltipPlugin = ({ toggle, enableToolTip, blockSize, enableSideLabel, key, className, style } = {}) => {
    let legendEl;

    const init = (u, opts) => {
        // Initialize internal legend
        legendEl = u.root.querySelector(".u-legend");

        // Update legend if side label is enabled
        if (enableSideLabel && blockSize) {
            extractAndUpdate(u, key, blockSize, toggle);
        }

        // Enable tooltip functionality
        if (enableToolTip) {
            if (className) {
                legendEl.classList.add(className);
            }
            uPlot.assign(legendEl.style, {
                textAlign: "left",
                pointerEvents: "none",
                display: "none",
                position: "absolute",
                left: -50,
                top: 0,
                zIndex: 100,
                boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                ...style
            });

            const overEl = u.over;
            overEl.style.overflow = "visible"; // Allow tooltip to exit plot
            overEl.appendChild(legendEl);

            overEl.addEventListener("mouseenter", () => {
                legendEl.style.display = null; // Show tooltip
            });
            overEl.addEventListener("mouseleave", () => {
                legendEl.style.display = "none"; // Hide tooltip
            });

        } else {
            legendEl.style.display = "none" // Hide legend if tooltip is disabled
        }
    }

    const update = (u) => {
        if (enableSideLabel) {
            extractAndUpdate(u, key, blockSize, toggle);
        }
        if (enableToolTip) {
            const { left, top } = u.cursor;
            const tooltipWidth = legendEl.offsetWidth;
            const tooltipHeight = legendEl.offsetHeight;
            const windowWidth = blockSize.chart.chartWidth
            const windowHeight = blockSize.chart.chartHeight
            // Calculate new position
            let newLeft = left + 10; // Default position
            let newTop = top + 10; // Default position

            // Adjust position if the tooltip overflows the right edge
            if (newLeft + tooltipWidth > windowWidth) {
                newLeft = left - tooltipWidth - 10;
            }

            // Adjust position if the tooltip overflows the left edge
            if (newLeft < 0) {
                newLeft = 10;
            }

            // Adjust position if the tooltip overflows the bottom edge
            if (newTop + tooltipHeight > windowHeight) {
                newTop = top - tooltipHeight - 10;
            }

            // Adjust position if the tooltip overflows the top edge
            if (newTop < 0) {
                newTop = top + 10;
            }

            // Set the tooltip position
            legendEl.style.transform = `translate(${newLeft}px, ${newTop}px)`;

            // Update tooltip content with individual colors
            // TODO Replace table with div structure
            // const seriesData = u.data; // Assuming u.data contains the series data
            // const tooltipContent = seriesData.map((series, index) => {
            //     const color = series.color || '#000'; // Get the color for the series
            //     const label = series.label || `Series ${index + 1}`; // Get the label for the series
            //     return `<div style="color: ${color};">${label}: ${series.value}</div>`; // Create a colored label
            // }).join('');
            // console.log(legendEl.innerHTML, tooltipContent)
            // legendEl.innerHTML = tooltipContent; // Set the tooltip content
        }
    }
    return {
        hooks: {
            init,
            setCursor: update,
        }
    };
};

/**
 * Calculate unique date strings for the given timestamps in a specified timezone.
 * 
 * @param {Object} params - The parameters object.
 * @param {Array} params.data - uPlot data array, where the first element contains timestamps in seconds since epoch.
 * @param {String} params.tz - Timezone string (e.g., 'Europe/Zurich').
 * @returns {Set} - A Set containing unique date strings formatted as 'YYYY-MM-DD'.
 */
const getUniqueDates = ({ data, tz }) => {
    const uniqueDays = new Set();

    // Iterate through each timestamp in the data array
    for (const timestamp of data[0]) {
        // Convert the timestamp from seconds to milliseconds and create a Date object
        const date = uPlot.tzDate(new Date(timestamp * 1000), tz); // Convert to local time based on the timezone

        // Format the date to 'YYYY-MM-DD'
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        // Add the formatted date to the Set for uniqueness
        uniqueDays.add(formattedDate);
    }

    return uniqueDays; // Return the Set of unique date strings
}

/**
 * Calculate timestamps for evening/morning shade of chart (sunset, sunrise).
 * 
 * @param {Object} sunset - Object with hour and minute data. Format: {hour: X, min: Y}.
 * @param {Object} sunrise - Object with hour and minute data. Format: {hour: X, min: Y}.
 * @param {Array} data - uPlot data array, where the first element contains timestamps in seconds since epoch.
 * @param {String} tz - Timezone string (e.g., 'Europe/Zurich').
 * @returns {Object} - An object per day with timestamps in seconds. 
 *                     Each key is the date, with morning and evening values together with day start and end values.
 */
const getNightSpanTimestamps = ({ sunset, sunrise, data, tz }) => {
    const dateSet = getUniqueDates({ data, tz });
    const nightSpanTimestamps = {};

    dateSet.forEach(date => {
        // Create a base date object for the current date
        const baseDate = uPlot.tzDate(new Date(date), tz);

        // Set morning and evening times
        const tzDateMorning = new Date(baseDate);
        tzDateMorning.setHours(sunrise.hour, sunrise.min, 0, 0);

        const tzDateEvening = new Date(baseDate);
        tzDateEvening.setHours(sunset.hour, sunset.min, 0, 0);

        // Set day start and end times
        const tzDateStart = new Date(baseDate);
        tzDateStart.setHours(0, 0, 0, 0);

        const tzDateEnd = new Date(baseDate);
        tzDateEnd.setHours(23, 59, 59, 999);

        // Store the timestamps in seconds
        nightSpanTimestamps[date] = {
            start: Math.floor(tzDateStart.getTime() / 1000),
            morning: Math.floor(tzDateMorning.getTime() / 1000),
            evening: Math.floor(tzDateEvening.getTime() / 1000),
            end: Math.floor(tzDateEnd.getTime() / 1000),
        };
    });

    return nightSpanTimestamps; // Return the object with timestamps
}


/**
 * Show in chart when it is nighttime (7 PM to 7 AM).
 * 
 * @param {Object} color - A pattern string for the night shade color.
 * @param {Object} dateWithTimestamps - Object with dates and timestamps
 * @param {Object} theme - Theme object containing typography settings.
 */
const drawNightShades = ({ color, dateWithTimestamps, theme }) => {

    const drawBackground = (u) => {
        const { ctx } = u;
        const { height } = u.bbox;

        ctx.save();
        const yStart = 0; // Start from the top of the canvas
        const yEnd = height; // End at the bottom of the canvas

        // Function to draw night shades
        const drawShade = (xStart, xEnd) => {
            ctx.fillStyle = color; // Set the fill color
            ctx.fillRect(xStart, yStart, xEnd - xStart, yEnd); // Draw the rectangle
        };

        // Iterate through each date and draw the night shades
        for (const date in dateWithTimestamps) {
            const { start, morning, evening, end } = dateWithTimestamps[date];

            // Draw from midnight to morning
            drawShade(u.valToPos(start, 'x', true), u.valToPos(morning, 'x', true));

            // Draw from evening to midnight
            drawShade(u.valToPos(evening, 'x', true), u.valToPos(end, 'x', true));
        }

        ctx.restore();
    };

    return {
        hooks: {
            drawClear: drawBackground,
        }
    };
};
/**
 * Define format.
 * @param {String} pattern - A pattern string
 *  */
const format = (pattern = "{YYYY}-{MM}-{DD} {HH}:{mm}:{ss}") => {
    return { fmtDate: uPlot.fmtDate(pattern) }
};


/**
 * Zoom Wheel function copied from uPlot demos (MIT)
 * See https://github.com/leeoniya/uPlot/blob/91de800538ee5d6f45f448d98b660a4a658e587b/demos/zoom-wheel.html#L36
 * @param {Object} opts - object with factor options
 *  */
const wheelZoomPlugin = (opts) => {
    let factor = opts.factor || 0.75;

    let xMin, xMax, yMin, yMax, xRange, yRange;

    function clamp(nRange, nMin, nMax, fRange, fMin, fMax) {
        if (nRange > fRange) {
            nMin = fMin;
            nMax = fMax;
        }
        else if (nMin < fMin) {
            nMin = fMin;
            nMax = fMin + nRange;
        }
        else if (nMax > fMax) {
            nMax = fMax;
            nMin = fMax - nRange;
        }

        return [nMin, nMax];
    }

    return {
        hooks: {
            ready: u => {
                xMin = u.scales.x.min;
                xMax = u.scales.x.max;
                yMin = u.scales.y.min;
                yMax = u.scales.y.max;

                xRange = xMax - xMin;
                yRange = yMax - yMin;

                let over = u.over;
                let rect = over.getBoundingClientRect();

                // wheel drag pan
                over.addEventListener("mousedown", e => {
                    if (e.button === 1) {
                        //	plot.style.cursor = "move";
                        e.preventDefault();

                        let left0 = e.clientX;
                        //	let top0 = e.clientY;

                        let scXMin0 = u.scales.x.min;
                        let scXMax0 = u.scales.x.max;

                        let xUnitsPerPx = u.posToVal(1, 'x') - u.posToVal(0, 'x');

                        function onmove(e) {
                            e.preventDefault();

                            let left1 = e.clientX;
                            //	let top1 = e.clientY;

                            let dx = xUnitsPerPx * (left1 - left0);

                            u.setScale('x', {
                                min: scXMin0 - dx,
                                max: scXMax0 - dx,
                            });
                        }

                        function onup(e) {
                            document.removeEventListener("mousemove", onmove);
                            document.removeEventListener("mouseup", onup);
                        }

                        document.addEventListener("mousemove", onmove);
                        document.addEventListener("mouseup", onup);
                    }
                });

                // wheel scroll zoom
                over.addEventListener("wheel", e => {
                    e.preventDefault();

                    let { left, top } = u.cursor;

                    let leftPct = left / rect.width;
                    let btmPct = 1 - top / rect.height;
                    let xVal = u.posToVal(left, "x");
                    let yVal = u.posToVal(top, "y");
                    let oxRange = u.scales.x.max - u.scales.x.min;
                    let oyRange = u.scales.y.max - u.scales.y.min;

                    let nxRange = e.deltaY < 0 ? oxRange * factor : oxRange / factor;
                    let nxMin = xVal - leftPct * nxRange;
                    let nxMax = nxMin + nxRange;
                    [nxMin, nxMax] = clamp(nxRange, nxMin, nxMax, xRange, xMin, xMax);

                    let nyRange = e.deltaY < 0 ? oyRange * factor : oyRange / factor;
                    let nyMin = yVal - btmPct * nyRange;
                    let nyMax = nyMin + nyRange;
                    [nyMin, nyMax] = clamp(nyRange, nyMin, nyMax, yRange, yMin, yMax);

                    u.batch(() => {
                        u.setScale("x", {
                            min: nxMin,
                            max: nxMax,
                        });

                        u.setScale("y", {
                            min: nyMin,
                            max: nyMax,
                        });
                    });
                });
            }
        }
    };
}

/**
 * Zoom Touch function copied from uPlot demos (MIT)
 * See https://github.com/leeoniya/uPlot/blob/91de800538ee5d6f45f448d98b660a4a658e587b/demos/zoom-touch.html#L25
 * @param {Object} opts - object with factor options
 *  */
function touchZoomPlugin(opts) {
    function init(u, opts, data) {
        let over = u.over;
        let rect, oxRange, oyRange, xVal, yVal;
        let fr = { x: 0, y: 0, dx: 0, dy: 0 };
        let to = { x: 0, y: 0, dx: 0, dy: 0 };

        function storePos(t, e) {
            let ts = e.touches;

            let t0 = ts[0];
            let t0x = t0.clientX - rect.left;
            let t0y = t0.clientY - rect.top;

            if (ts.length === 1) {
                t.x = t0x;
                t.y = t0y;
                t.d = t.dx = t.dy = 1;
            }
            else {
                let t1 = e.touches[1];
                let t1x = t1.clientX - rect.left;
                let t1y = t1.clientY - rect.top;

                let xMin = Math.min(t0x, t1x);
                let yMin = Math.min(t0y, t1y);
                let xMax = Math.max(t0x, t1x);
                let yMax = Math.max(t0y, t1y);

                // midpts
                t.y = (yMin + yMax) / 2;
                t.x = (xMin + xMax) / 2;

                t.dx = xMax - xMin;
                t.dy = yMax - yMin;

                // dist
                t.d = Math.sqrt(t.dx * t.dx + t.dy * t.dy);
            }
        }

        let rafPending = false;

        function zoom() {
            rafPending = false;

            let left = to.x;
            let top = to.y;

            // non-uniform scaling
            //	let xFactor = fr.dx / to.dx;
            //	let yFactor = fr.dy / to.dy;

            // uniform x/y scaling
            let xFactor = fr.d / to.d;
            let yFactor = fr.d / to.d;

            let leftPct = left / rect.width;
            let btmPct = 1 - top / rect.height;

            let nxRange = oxRange * xFactor;
            let nxMin = xVal - leftPct * nxRange;
            let nxMax = nxMin + nxRange;

            let nyRange = oyRange * yFactor;
            let nyMin = yVal - btmPct * nyRange;
            let nyMax = nyMin + nyRange;

            u.batch(() => {
                u.setScale("x", {
                    min: nxMin,
                    max: nxMax,
                });

                u.setScale("y", {
                    min: nyMin,
                    max: nyMax,
                });
            });
        }

        function touchmove(e) {
            storePos(to, e);

            if (!rafPending) {
                rafPending = true;
                requestAnimationFrame(zoom);
            }
        }

        over.addEventListener("touchstart", function (e) {
            e.preventDefault()
            rect = over.getBoundingClientRect();

            storePos(fr, e);

            oxRange = u.scales.x.max - u.scales.x.min;
            oyRange = u.scales.y.max - u.scales.y.min;

            let left = fr.x;
            let top = fr.y;

            xVal = u.posToVal(left, "x");
            yVal = u.posToVal(top, "y");

            document.addEventListener("touchmove", touchmove, { passive: true });
        });

        over.addEventListener("touchend", function (e) {
            document.removeEventListener("touchmove", touchmove, { passive: true });
        });
    }

    return {
        hooks: {
            init
        }
    };
}
/**
 * Download chart.
 * @param {String} canvasId - id of canvas element
 * @param {Object} uplotInstance - Uplot instance
 *  */
const exportPng = (canvasId, uplotInstance) => {
    // Get the export canvas
    const exportCanvas = document.getElementById(canvasId);
    const ctx = exportCanvas.getContext("2d");
    // console.log(uplotInstance)
    // Set canvas size
    exportCanvas.width = uplotInstance.width;
    exportCanvas.height = uplotInstance.height;
    ctx.fillStyle = "white"; // Set the fill color to white
    ctx.fillRect(0, 0, exportCanvas.width, exportCanvas.height);
    // Access the internal canvas of uPlot
    const internalCanvas = uplotInstance.root.querySelector('canvas');

    // Draw the internal canvas onto the export canvas
    ctx.drawImage(internalCanvas, 0, 0, uplotInstance.width, uplotInstance.height);

    // Convert canvas to PNG
    const pngUrl = exportCanvas.toDataURL("image/png");

    // Create a link to download the PNG
    const link = document.createElement("a");
    link.href = pngUrl;
    link.download = "uplot-chart.png";
    link.click();
};

export {
    updateRequired,
    doesMatch,
    timeZoneData,
    syncCursers,
    formatDates,
    formatAxes,
    defineYSeries,
    defineYScales,
    drawNightShades,
    getUniqueDates,
    getNightSpanTimestamps,
    exportPng,
    format,
    wheelZoomPlugin,
    touchZoomPlugin,
    legendAndTooltipPlugin,
    mapData,
    makeChart,
}