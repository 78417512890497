import React, { useState, useEffect } from "react";

import { DateObject } from "react-multi-date-picker";
import { useTheme, Paper, Stack, useMediaQuery, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from "react-i18next";
import RangeButton from "./RangeButton";
import RangeCalendar from "./RangeCalendar";
import i18next from 'i18next';
import { DATERANGES, BORDER_RADIUS, PAPER_ELEVATION, hotTranslate } from '../../../constants';
import { setDateTimeOnKey } from "../../Generic/RangePicker"



const RangeCalendarView = ({ popper, rangeHandler, actualRange, updateValues, actualValues }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const debug = false
    const [tempValues, setTempValues] = useState(actualValues);
    const [tempRange, setTempRange] = useState(actualRange);
    const [fullYear, setFullYear] = useState(false);
    const [rangeType, setRangeType] = useState(() => {
        return hotTranslate(DATERANGES);
    });

    // Hot language change
    useEffect(() => {
        const languageChangedHandler = () => {
            setRangeType(prevRangeType => hotTranslate(prevRangeType));
        };

        i18next.on('languageChanged', languageChangedHandler);

        return () => {
            i18next.off('languageChanged', languageChangedHandler);
        };
    }, []);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));



    // Update parents if popper closes
    useEffect(() => {
        if (!popper && tempValues && tempValues.length > 1) {
            updateValues(tempValues)
            rangeHandler(tempRange)
        }
    }, [popper,updateValues,rangeHandler,tempValues,tempRange]);

    const handleRange = (val, range) => {
        let updatedRange = rangeType.map(obj => {
            if (obj.id === val) return { ...obj, selected: true }
            else return { ...obj, selected: false }
        })
        let thisElement = updatedRange.find(f => f.selected === true)
        // Set selected range for back/forward buttons
        setTempRange(thisElement)
        const newObject = setDateTimeOnKey(thisElement.value)
        // convert to value
        const dateStart = new DateObject(newObject.startDateTime);
        const dateEnd = new DateObject(newObject.endDateTime);

        if (debug) console.log("Set values: ", dateStart, dateEnd)
        setTempValues([dateStart, dateEnd])
    }

    return (
        <Paper
            sx={{
                width: isMobile ? "100%" : isTablet ? "100%" : null,
                padding: '1rem',
                height: 'auto', // 350
                borderRadius: `${BORDER_RADIUS}px`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'center',

            }}
            id="range-select-paper"
            elevation={PAPER_ELEVATION}
        >
            <Stack
                direction="row"
                spacing={0.5}
                style={{
                    paddingBottom: "1rem",
                }}>
                <Stack
                    direction="column"
                    spacing={1}
                    style={{
                        paddingBottom: "1rem",
                    }}
                >
                    {rangeType.slice(0, 5).map((obj, index) => (
                        <RangeButton key={`range1-${index}`} allObj={rangeType} singleObj={obj} handleRange={handleRange} />
                    ))}
                    {!isMobile && (
                        <FormGroup style={{ paddingLeft: "0px" }}>
                            <FormControlLabel
                                onChange={() => setFullYear(!fullYear)}
                                control={<Checkbox />}
                                label={t("FULL_YEAR")}
                            />
                        </FormGroup>
                    )}
                </Stack>
                <div
                    style={{
                        paddingBottom: "0rem",
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}
                >
                </div>
                <RangeCalendar
                    fullYear={fullYear}
                    actualValues={tempValues}
                    updateValues={setTempValues}
                    rangeHandler={setTempRange}
                />
                <Stack
                    direction="column"
                    spacing={1}
                    style={{
                        paddingLeft: "1rem", paddingBottom: "1rem",
                    }}>
                    {rangeType.slice(5).map((obj, index) => (
                        <RangeButton key={`range2-${index}`} allObj={rangeType} singleObj={obj} handleRange={handleRange} />

                    ))}
                </Stack>
            </Stack>
        </Paper>
    )
}
export default RangeCalendarView