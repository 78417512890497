// Tree structure
/*
[
 {
    internalId: 'grid',
    label: 'Data Grid',
    children: [
      { internalId: 'grid-community', label: '@mui/x-data-grid' },
      { internalId: 'grid-pro', label: '@mui/x-data-grid-pro' },
      { internalId: 'grid-premium', label: '@mui/x-data-grid-premium' },
    ],
  },
  ...
]
*/

export const TreeService = {
  renderTree(subgroup, nodes) {
    //console.log(templates)
    const result = subgroup;
    subgroup?.forEach((element, ind) => {
      var newSubgroup = [];

      if (Array.isArray(element.children) && element.children.length > 0) {
        newSubgroup = nodes.filter((f) =>
          element.children.some((k) => k["id"] === f["id"])
        );
      }

      if (newSubgroup.length > 0) {
        element["children"] = this.renderTree(newSubgroup, nodes);
      } else {
        element["children"] = []; //element //
      }
    });
    return result;
  },

  dynamicSort(property) {
    return function (a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  },

  uniqueIds(list) {
    const seenIds = new Set();
    const uniqueList = list.filter((value) => {
      if (!seenIds.has(value.id)) {
        seenIds.add(value.id);
        return true; // Keep this item
      }
      return false; // Skip this item
    });
    return uniqueList;
  },

  // build a list, one level deep
  buildTreeData(data, filters) {
    const result = [];

    // Create a map for quick access to parents by their IDs
    const parentMap = new Map(data?.map((item) => [item.id, item]));

    filters.forEach((filter) => {
      const { key, childRef, marker, parentRef } = filter;

      // use endsWith because of the '@' in the string

      let filteredData = data?.filter((w) => w["specRef"].endsWith(key)) || [];

      // Remove items that are already in the result
      filteredData = filteredData.filter(
        (item) => !result.some((resItem) => resItem.id === item.id)
      );

      filteredData.forEach((element) => {
        // Direct children based on the reference and marker
        const children =
          data?.filter(
            (w) => w[childRef] === element.id && Object.keys(w).includes(marker)
          ) || [];

        element["children"] = children.sort(this.dynamicSort("navName"));

        // Define parents
        const parentId = element[parentRef];
        element["parent"] = parentId ? parentMap.get(parentId) : null;
        result.push(element);
      });
    });
    // console.log("result build nodes", result)
    return result;
  },

  arrayExist(val) {
    if (
      val !== undefined &&
      val !== null &&
      Array.isArray(val) &&
      val.length > 0
    )
      return true;
    else return false;
  },
  objectExist(val) {
    if (val !== undefined && val !== null && typeof val === "object")
      return true;
    else return false;
  },

  getDisOrNavName(data) {
    if ("navName" in data) return data.navName;
    else return data.dis;
  },
  // create a list of ids
  expandedItems(tree) {
    return tree.map((m) => {
      return m.id;
    });
  },

  // ,
  convertTreeData(data) {
    return data.map((m) => {
      let x = { ...m };
      x["label"] = this.getDisOrNavName(m);
      x["internalId"] = m.id;
      return x;
    });
  },
  // -------------------------
  // ENTRY POINT
  // -------------------------
  // data must be an array, filters must be an array
  getTree(data, filters) {
    // Filter for unique ids and make an deep immutable copy of data
    const dataImmute = structuredClone(this.uniqueIds(data));

    if (filters === undefined) return [];

    // enrich data with label and internalId tag
    const enrichedData = this.convertTreeData(dataImmute);

    // build a flatlist
    const treeData = this.buildTreeData(enrichedData, filters);

    // build tree structure, extract the first layer
    const grandparents =
      dataImmute === null ? [] : treeData.filter((f) => !f.parent);
    const renderedTree = this.renderTree(grandparents, treeData);
    // console.log("result", renderedTree, enrichedData) // @2ebf3dbf-0f0ed7d5
    return { tree: renderedTree, flatIdList: this.expandedItems(treeData) };
  },
};
