import chroma from 'chroma-js';
import { TARGET_TREE_WIDTH } from "../../constants";

/**
 * Convert HEX to HSL
 * @param {string} hex - The color in HEX format.
 * @returns {Array} - The HSL representation [hue, saturation, lightness].
 */
function hexToHsl(hex) {
    let r = parseInt(hex.slice(1, 3), 16) / 255;
    let g = parseInt(hex.slice(3, 5), 16) / 255;
    let b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default:
                console.log('Unknown case.');
        }
        h /= 6;
    }

    return [h * 360, s * 100, l * 100]; // Return HSL values
}

/**
 * Convert HSL to HEX
 * @param {number} h - Hue (0-360).
 * @param {number} s - Saturation (0-100).
 * @param {number} l - Lightness (0-100).
 * @returns {string} - The color in HEX format.
 */
function hslToHex(h, s, l) {
    let r, g, b;

    s /= 100;
    l /= 100;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h / 360 + 1 / 3);
        g = hue2rgb(p, q, h / 360);
        b = hue2rgb(p, q, h / 360 - 1 / 3);
    }

    return `#${((1 << 24) + (Math.round(r * 255) << 16) + (Math.round(g * 255) << 8) + Math.round(b * 255)).toString(16).slice(1)}`;
}

/**
 * Check if a color is dark
 * @param {string} hex - The color in HEX format.
 * @returns {boolean} - True if the color is dark, false otherwise.
 */
function isDark(hex) {
    // eslint-disable-next-line
    const [h, s, l] = hexToHsl(hex);
    return l < 50; // Assuming lightness below 50% is considered dark
}

/**
 * Calculate the complementary color based on two colors
 * @param {string} color1 - The first color in HEX format.
 * @param {string} color2 - The second color in HEX format.
 * @returns {string} - The complementary color in HEX format.
 */
function calculateComplementaryColor(color1, color2) {
    // Convert to HSL
    const [h1, s1, l1] = hexToHsl(color1);

    // eslint-disable-next-line
    const [h2, s2, l2] = hexToHsl(color2);

    // Calculate the average hue
    const averageHue = (h1 + h2) / 2;

    // Calculate the complementary hue (180 degrees opposite)
    const complementaryHue = (averageHue + 180) % 360;

    // Use the saturation and lightness from the first color
    let complementaryColor = hslToHex(complementaryHue, s1, l1);

    // Brighten the color if it is dark
    if (isDark(complementaryColor)) {
        const [h, s, l] = hexToHsl(complementaryColor);
        complementaryColor = hslToHex(h, s, Math.min(l + 30, 100)); // Increase lightness by 30%
    }

    return complementaryColor;
}

/**
 * Generate a color palette based on primary and secondary colors.
 * @param {string} primaryColor - The primary color in HEX format.
 * @param {string} secondaryColor - The secondary color in HEX format.
 * @param {number} n - The number of colors to generate.
 * @returns {string[]} - An array of color HEX codes.
 */
export const generateColorPalette = (primaryColor, secondaryColor, n) => {
    // Create a color scale using the primary and secondary colors
    const color3 = calculateComplementaryColor(primaryColor, secondaryColor);
    const scale = chroma.scale([primaryColor, color3, secondaryColor]).mode('lab').colors(n);
    return scale;
}


/**
 * Calculate all sizes for a Chartblock, depending on size of parent element and side or bottom menu
 * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @param {String} sizeDefiningId - ID of parent div
 * @param {Number} drawerWidth - Width of side menu. If 0 => mobile
 * @param {Object} chartDefs - Object with chart definitions
 * @param {Array} sizeList - [isMobile,isTablet,isLargeTablet]
 *  */
export const getChartBlockSizes = (sizeDefiningId, drawerWidth, chartDefs, sizeList, toolNumber = 5) => {

    const debug = false
    const parentElement = document.getElementById(sizeDefiningId);
    if (!parentElement) return []

    if (debug) console.log("Workspace dimensioning - sizeList", sizeList)
    const widthPercChartBlockPercentage = 100 // %
    const minChartHeight = 300 
    // consider tree
    const widthWorkspace = parentElement.offsetWidth 
    
    if (debug) console.log("Workspace dimensioning - widthWorkspace", widthWorkspace)
    if (debug) console.log("Workspace dimensioning - isMobile", sizeList[0])
    
    const relationFactor = sizeList[0] ? 1 : sizeList[1] ? 2.5 : 3.8
    const numberOfPoints = chartDefs ? chartDefs.map(e => e.points).flat().length : 0
    const paddingCard = sizeList[0] ? 8 : 24 // in px
    // const heightPerPointChart = (drawerWidth === 0) ? 50 : 100
    if (debug) console.log("Workspace dimensioning - relationFactor", relationFactor)

    // legend requires around 320px if marker is ommitted (border-box => includes padding and reduces content)
    const legendWidth = sizeList.some(size => size) ? 0 : 200

    const legendHeightPerPoint = 40
    const legendMarginPerPoint = 2
    const legendWidthLabel = 0.75 * legendWidth
    const legendWidthMarker = 0
    // const legendFontSize = Math.min(16, Math.max((widthWorkspace / workspaceLegendSplit - 0.2) * 12, 12))
    const legendFontSize = 12
    const legendWidthValue = sizeList.some(size => size)  ? 0 : legendWidth - legendWidthLabel - legendWidthMarker
    const legendTotalHeight = sizeList.some(size => size) ? 0 : ((numberOfPoints + 2) * legendHeightPerPoint + numberOfPoints * legendMarginPerPoint) - 2 * paddingCard
    if (debug) console.log("Workspace dimensioning - legend total width?", (!sizeList[0] && !sizeList[1] && sizeList[2]), legendWidth)
    if (debug) console.log("Workspace dimensioning - legend value width?", legendWidthValue)
    if (debug) console.log("Workspace dimensioning - legend total height?", legendTotalHeight)
    // toolbar
    const toolHeight = toolNumber * 28
    const toolbarWidth = 40

    // Charts
    const chartWidth = widthWorkspace  - toolbarWidth - legendWidth - 2 * paddingCard
    const chartHeight = Math.max(legendTotalHeight, chartWidth / relationFactor, toolHeight, minChartHeight)
    if (debug) console.log("Workspace dimensioning - chart total width/height", chartWidth, chartHeight)
    // Kpis
    const kpiWidth = widthWorkspace * (100 - widthPercChartBlockPercentage) / 100
    const maxNumberKpis = 3
    const kpiHeight = chartHeight / 3
    return {
        legend: {
            legendWidth: legendWidth,
            legendHeightPerPoint: legendHeightPerPoint,
            legendMarginPerPoint: legendMarginPerPoint,
            legendWidthLabel: legendWidthLabel,
            legendWidthMarker: legendWidthMarker,
            legendWidthValue: legendWidthValue,
            legendTotalHeight: legendTotalHeight,
            legendFontSize: legendFontSize
        },
        chart: {
            chartWidth: chartWidth,
            chartHeight: chartHeight
        },
        toolbar: {
            toolbarWidth: toolbarWidth
        },
        kpi: {
            kpiWidth: kpiWidth,
            maxNumberKpis: maxNumberKpis,
            kpiHeight: kpiHeight
        },
        parent: {
            width: chartWidth + toolbarWidth + legendWidth,
            padding: paddingCard,
            height: chartHeight,
            titleHeight: '35px',
            workspaceWidth: widthWorkspace,
            drawerWidth: drawerWidth
        },
    };
};

/**
 * Converts a hex string with 3 or 6 letters to an rgb(a,b,c,alpha) format
 * @param {String} hex - Color in hex format
 * @param {Number} alpha - Alpha value
 *  */
export const hexToRgba = (hex, alpha = 1) => {

    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    let r, g, b;

    // If the hex is 3 digits (e.g., #fff), convert it to 6 digits (e.g., #ffffff)
    if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
    }

    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

/**
 * Converts RGB values to a hex string format.
  * Converts RGB values or an RGB string to a hex string format.
 * @param {Number|String} r - The red component (0-255) or an RGB string (e.g., "rgb(30, 24, 4)").
 * @param {Number} [g] - The green component (0-255). Required if r is a number.
 * @param {Number} [b] - The blue component (0-255). Required if r is a number.
 * @returns {String} - The color in hex format (e.g., "#ff6347").
 */
export const rgbToHex = (r, g, b) => {
    let red, green, blue;

    // Check if the input is a string in "rgb(r, g, b)" format
    if (typeof r === 'string' && r.startsWith('rgb(') && r.endsWith(')')) {
        // Extract the numbers from the string
        const rgbValues = r.slice(4, -1).split(',').map(Number);
        [red, green, blue] = rgbValues; // Destructure the array into red, green, blue
    } else {
        // Ensure the RGB values are within the range of 0-255
        red = Math.max(0, Math.min(255, r));
        green = Math.max(0, Math.min(255, g));
        blue = Math.max(0, Math.min(255, b));
    }

    // Convert each component to a two-digit hexadecimal string
    const toHex = (c) => {
        const hex = c.toString(16); // Convert to hex
        return hex.length === 1 ? '0' + hex : hex; // Pad with zero if needed
    };

    // Combine the hex values and return the final string
    return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}


// Test function
export const generateTestData = (numDataPoints, numSeries) => {

    const data = [];

    // Calculate the start and end timestamps (5 months ago to now)
    const fiveMonthsAgo = new Date(Date.now() - 5 * 30 * 24 * 60 * 60 * 1000);
    const now = new Date();

    // Generate random timestamps in epoch milliseconds between the start and end timestamps, and sort them
    const timestamps = Array.from({ length: numDataPoints }, () => {
        const randomTime = fiveMonthsAgo.getTime() + Math.random() * (now.getTime() - fiveMonthsAgo.getTime()) / 1000;
        return Math.floor(randomTime);
    }).sort((a, b) => a - b);
    // Generate random values for each data series
    for (let i = 0; i < numSeries; i++) {
        const seriesData = Array.from({ length: numDataPoints }, () => Math.floor(Math.random() * 50));
        data.push(seriesData);
    }

    // Combine timestamps and data series
    data.unshift(timestamps);
    return data;
}

export const getElementHeightById = (id) => {
    const element = document.getElementById(id);
    if (element) {
        return element.offsetHeight; // Return the height of the element
    }
    return null; // Return null if the element is not found
}

export const hackDemo = (str) => {
    if (str && str.endsWith("-demo")) {
        return str.replace(/-demo$/, "");
    }
    return str;
};